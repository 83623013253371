import React, {Component} from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Select from 'react-select';
import Alert from 'react-s-alert';
import { config } from '../constant';

import plus from "../../static/images/plus.png";
import dlt from "../../static/images/delete.png";
import dltBtn from "../../static/svg/delete.svg";
import { commonFunction } from '../common-functions';

class FieldMetaData extends Component{
    constructor(props){
        super(props);
        this.state = {
            tid: this.props.tid,
            stepValidator: [false, false, false],
            submitting: false,
            formObject: {},
            markers: [],
            stepNumber: 0,
            fetched: false,
            steps: [...config.metaSteps],
            fieldTypes: [...config.fileds],
            dateFormats: commonFunction.dateFormats.map((el) => {
                return {
                    label: `${el} (e.g. ${dayjs().format(el)})`,
                    value: el
                }
            }),
            fileFormats: [...config.fileFormats],
            groupName: '',
            groups: [],
            groupObject: {},
            groupUsedKeys: [],
            groupNameIndex: -1,
            create: true,
            output: {
                format: 'docx',
                logo: undefined
            }
        };
    }

    createUpdateGroups(){
        if(this.state.groupUsedKeys.length === this.state.markers.length){
            Alert.error(`Sorry! Not enough markers to create separate group.`, {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 5000
            });
        }
        else{
            if(this.state.groupName){
                var groupObject = {...this.state.groupObject},
                    groups = [...this.state.groups];
                if(this.state.groupNameIndex > -1){
                    var data = [...groupObject[groups[this.state.groupNameIndex]]];

                    if(groupObject[this.state.groupName] === undefined){
                        groupObject[this.state.groupName] = data;
                        delete groupObject[groups[this.state.groupNameIndex]];
                        groups[this.state.groupNameIndex] = this.state.groupName;
                        this.setState({
                            groupName: '',
                            groups: groups,
                            groupObject: groupObject,
                            groupNameIndex: -1
                        });
                    }
                    else Alert.error(`Sorry! Two group name can't be same.`, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                }
                else if(this.state.groups.indexOf(this.state.groupName) === -1){
                    const unusedKeys = this.state.markers.filter(
                        el => this.state.groupUsedKeys.indexOf(el.key) === -1
                    ).map(el => el.key);
                    groupObject[this.state.groupName] = unusedKeys;
                    groups.push(this.state.groupName);
                    this.setState({
                        groupName: '',
                        groups: groups,
                        groupObject: groupObject,
                        groupNameIndex: -1,
                        groupUsedKeys: [...this.state.groupUsedKeys, ...unusedKeys]
                    }, () => { 
                        this.validateForm(1);
                    })
                }
                else Alert.error(`Sorry! Two group name can't be same.`, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
            }
        }
    }

    validateForm(stepNumber){
        var step = [...this.state.stepValidator],
            flag = true;
        if(stepNumber === 0){
            var key,
                flagCounter = 0,
                keyCounter = 0;
            const formObject = {...this.state.formObject};
            for(key in formObject){
                keyCounter++;
                if(formObject[key].helpText !== ''){
                    if(formObject[key].hasOption){
                        if(formObject[key].type === 'file' || formObject[key].type === 'date'){
                            if(formObject[key].format){ 
                                formObject[key].isError = false;
                                flagCounter++;
                            }
                            else{ 
                                formObject[key].isError = true;
                                break
                            };
                        }
                        else if(formObject[key].options.length > 0){ 
                            formObject[key].isError = false;
                            flagCounter++;
                        }
                        else{
                            formObject[key].isError = true;
                            break;
                        }
                    }
                    else{ 
                        formObject[key].isError = false;
                        flagCounter++;
                    }
                }
                else{
                    formObject[key].isError = true;
                    break;
                };
            }

            if(flagCounter === keyCounter){
                step[stepNumber] = true;
                this.setState({
                    stepValidator: step
                });
            }
            else{
                flag = false;
                if(this.state.stepValidator[stepNumber]){
                    step[stepNumber] = false;
                    this.setState({
                        stepValidator: step
                    });
                }
            }
            this.setState({ formObject });
        }
        else if(stepNumber === 1){
            if(this.state.groupUsedKeys.length === this.state.markers.length){
                step[stepNumber] = true;
                this.setState({
                    stepValidator: step
                })
            }
            else{
                flag = false;
                if(this.state.stepValidator[stepNumber]){
                    step[stepNumber] = false;
                    this.setState({
                        stepValidator: step
                    });
                }
            }
        }
        else if(stepNumber === 2){
            step[stepNumber] = true;
            this.setState({
                stepValidator: step
            });
        }
        return flag;
    }

    setGroup(group, key){
        var gkeys = [...this.state.groupUsedKeys],
            gkeyIndex = gkeys.indexOf(key),
            gobj = {...this.state.groupObject},
            index = gobj[group].indexOf(key),
            flag = false;
        
        if(gkeyIndex === -1 && index === -1){
            gobj[group].push(key);
            gkeys.push(key);
            flag = true;
        }
        else if(gkeyIndex > -1 && index > -1){
            gobj[group].splice(index, 1);
            gkeys.splice(gkeyIndex, 1);
            flag = true;
        }
        else Alert.error('Sorry, this marker has already been used in other group. Please remove it from other group to add it here.', {
            position: 'bottom-right',
            effect: 'slide',
            timeout: 5000
        });

        if(flag) this.setState({
            groupObject: gobj,
            groupUsedKeys: gkeys
        }, () => {
            this.validateForm(1);
        })
    }

    editGroupName(value){
        this.setState({
            groupName: value[0],
            groupNameIndex: this.state.groups.indexOf(value[0])
        })
    }

    deleteGroup(value){
        var gobj = {...this.state.groupObject},
            gkeys = [...this.state.groupUsedKeys],
            groups = [...this.state.groups],
            gnameIndex = groups.indexOf(value[0]),
            i = 0;

        for(; i<value[1].length; i++){
            var index = gkeys.indexOf(value[1][i]);
            if(index > -1)
                gkeys.splice(index, 1);
        }
        groups.splice(gnameIndex, 1);
        delete gobj[value[0]];
        this.setState({
            groupObject: gobj,
            groupUsedKeys: gkeys,
            groups: groups
        });
    }

    bindEnter(e, key){
        if(e.keyCode === 13)
            this.setInput('option', key);
    }

    setInput(type, key, value, fkey){
        if(!fkey){
            var formObject = {...this.state.formObject},
                i = 0,
                sType;

            switch(type){
                case 'type':
                    for(; i<this.state.fieldTypes.length; i++)
                        if(this.state.fieldTypes[i].value === value){
                            sType = this.state.fieldTypes[i];
                            break;
                        }

                    formObject[key].type = value;
                    formObject[key].hasOption = sType.needOption;
                    
                    if(value === 'date' || value === 'file') formObject[key][''] = value === 'date' ? this.state.dateFormats[0].value : this.state.fileFormats[0].value;
                    else if(sType.needOption && formObject[key].options === undefined){
                        formObject[key]['options'] = [];
                        formObject[key]['optionForm'] = {
                            label: '',
                            value: '',
                            isDefault: false,
                            isValid: false
                        };
                        formObject[key]['selectedIndex'] = [];
                    }

                    if(!sType.needOption && formObject[key].options !== undefined){ 
                        delete formObject[key]['options'];
                        delete formObject[key]['optionForm'];
                        delete formObject[key]['selectedIndex'];
                    }
                    if(!sType.needOption && formObject[key].format !== undefined) delete formObject[key]['format'];
                break;

                case 'option':
                    if(formObject[key].optionForm.label && formObject[key].optionForm.value){
                        if(formObject[key].options === undefined) formObject[key].options = [];
                        formObject[key].options.push({
                            label: formObject[key].optionForm.label,
                            value: formObject[key].optionForm.value,
                            default: formObject[key].optionForm.isDefault
                        });
                        formObject[key].optionForm = {
                            label: '',
                            value: '',
                            isDefault: false,
                            isValid: false
                        };
                    }
                break;

                case 'selectedIndex':
                    let selectedIndex = formObject[key]['selectedIndex'] ? [...formObject[key]['selectedIndex']] : [],
                        index = selectedIndex.indexOf(value);

                    if(index === -1) selectedIndex.push(value);
                    else selectedIndex.splice(index, 1);

                    formObject[key].selectedIndex = selectedIndex;
                break;

                case 'deleteOption':
                    let indexes = formObject[key]['selectedIndex'] ? commonFunction.quickSort([...formObject[key]['selectedIndex']]) : [],
                        options = [...formObject[key].options];

                    if(indexes.length > 0){
                        for(i = indexes.length - 1; i >= 0; i--)
                            options.splice(indexes[i], 1);

                        formObject[key].selectedIndex = [];
                        formObject[key].options = options;
                    }
                break;

                case 'label':
                case 'value':
                case 'isDefault':
                    let optionForm = {...formObject[key].optionForm},
                        res;

                    optionForm[type] = value;
                    res = commonFunction.isObjectValid({
                        label: optionForm.label, 
                        value: optionForm.value
                    });
                    optionForm.isValid = (res.flag === res.key);

                    formObject[key].optionForm = optionForm;
                break;

                case 'helpText':
                    formObject[key][type] = value;
                    formObject[key].isTouched = true;
                break;

                default:
                    formObject[key][type] = value
                break;
            };

            this.setState({
                formObject: formObject
            }, () => {
                this.validateForm(0);
            })
        }
    }

    outputForm(type, data){
        const output = {...this.state.output};
        if(type === 'format'){
            output.format = data.value;
        }
        else{
            if(data.target.files.length > 0){ 
                output.logo = data.target.files[0];
            }
            else{
                output.logo = undefined;
            }
        }
        this.setState({
            output
        }, () => { 
            this.validateForm(2);
        })
    }

    getMetaDataDetails(callback){
        axios.all([
            axios.get(`${config.API_URL}api/v1/gettemplatemetadatabyid/${this.state.tid}/`),
            axios.get(`${config.API_URL}api/v1/gettemplatemetagroupbyid/${this.state.tid}/`)
        ])
        .then(axios.spread((metadata, metagroup) => { 
            callback(metadata.data, metagroup.data);
        }))
        .catch(() => {
            callback(undefined, undefined);
        })
    }

    saveMetadata(e){
        e.preventDefault();
        if(this.state.stepValidator[0] && this.state.stepValidator[1] && this.state.stepValidator[2]){
            this.setState({
                submitting: true
            }, () => {
                var formObject = {...this.state.formObject},
                    key;
                for(key in formObject){
                    delete formObject[key].isTouched;
                    if(formObject[key].optionForm !== undefined) delete formObject[key]['optionForm'];
                    if(formObject[key].selectedIndex !== undefined) delete formObject[key]['selectedIndex'];
                }
                this.props.submit(formObject, this.state.groupObject, this.state.tid, this.state.create, this.state.output);
            })
        }
    }

    nextButtonClicked(){
        var formObject = {...this.state.formObject};
        for(var key in formObject){
            formObject[key].isTouched = true;
        }
        this.setState({
            formObject
        });
    }

    componentDidMount(){
        axios.get(`${config.API_URL}api/v1/document/markers/${this.state.tid}/`)
        .then((marker) => { 
            var tempArray = [],
            formObject = {};

            if(marker.data.markers){
                let markers = marker.data.markers,
                    isImage, 
                    isParagraph;

                Object.keys(markers).forEach(key => {
                    if(markers[key] === true){ 
                        isImage = commonFunction.isImageField(key);
                        isParagraph = commonFunction.isParaField(key);
        
                        tempArray.push({
                            key: key,
                            name: isImage || isParagraph ? key.split(' ')[0] : key.replace(/_/g, ' '),
                            istable: false,
                            isImage: isImage,
                            isPara: isParagraph,
                        });
                        
                        formObject[key] = {
                            required: true,
                            type: isParagraph ? 'textarea' : (isImage || isParagraph ? 'file' : 'text'),
                            helpText: "",
                            hasOption: false,
                            isTouched: false,
                            isError: false
                        }
                        if(isImage) formObject[key]['format'] = this.state.fileFormats[0].value;
                        if(isParagraph) formObject[key]['format'] = this.state.fileFormats[1].value;
                    }
                    else tempArray.push({
                        key: key,
                        name: key.replace(/_/g, ' '),
                        istable: true
                    });
                });
            }

            this.getMetaDataDetails((metadata, metagroup) => {
                var groups = [],
                    groupObject = {},
                    groupUsedKeys = [],
                    create = true,
                    key;

                if(metadata !== undefined){
                    metadata = metadata[0];
                    metagroup = {...metagroup[0]};
                    // groupObject = {...metagroup[0].data};
                    create = false;
                    
                    for(key in formObject){
                        if(metadata.data[key]){ 
                            formObject[key] = metadata.data[key];

                            if(formObject[key] && formObject[key].hasOption && formObject[key].options && formObject[key].options.length > 0){
                                formObject[key]['selectedIndex'] = [];
                                formObject[key]['optionForm'] = {
                                    label: '',
                                    value: '',
                                    isDefault: false,
                                    isValid: false
                                };
                            }
                        }
                    }

                    groupObject = metagroup.data;
                    for(var akey in metagroup.data){
                        for(var i=0; i<metagroup.data[akey].length; i++)
                            groupUsedKeys.push(metagroup.data[akey][i])
                    }
                }

                this.setState({
                    markers: tempArray,
                    fetched: true,
                    formObject: formObject,
                    groups: groups,
                    groupObject: groupObject,
                    groupUsedKeys: groupUsedKeys,
                    create: create
                }, () => {
                    this.validateForm(0);
                });
            })
        });
    }

    render(){
        return(
            <div>
                {this.state.fetched && 
                    <div className="full-page-overlay">
                        <div className="flex-container full-height vertical-middle">
                            <div className="big-modal">
                                <div className="modal-body relative">
                                    <div className="close" onClick={() => this.props.callback('generateMetaData', false)}>
                                        <img src={dltBtn} alt="close"/>
                                    </div>
                                    <div className="flex-container">
                                        <div className="flexbox step">
                                            <div className="heading">Metadata generation</div>
                                            <p className="desc">Create metadata for each field, before sharing the template with people</p>
                                            <ul className="steps">
                                                {
                                                    this.state.steps.map((el, index) => (
                                                        <li key={index} className={`relative${(index === this.state.stepNumber ? ' active' : '')}${(this.state.stepNumber > index ? ' done' : '')}`}>
                                                            <div className="step-heading">{`Step ${index+1}. ${el.title}`}</div>
                                                            <div className="btn-desc">{el.desc}</div>
                                                        </li>
                                                    ))
                                                } 
                                            </ul>
                                        </div>
                                        <div className="flexbox action">
                                            <div className="main-heading">{`Step ${this.state.stepNumber + 1}. ${this.state.steps[this.state.stepNumber].title}`}</div>
                                            <div className="more-desc">{this.state.steps[this.state.stepNumber].moreDesc}</div>
                                            
                                            <form 
                                                method="post" 
                                                encType="multipart/form-data" 
                                                onSubmit={(e) => this.saveMetadata(e)}
                                            >
                                            {this.state.stepNumber === 0 && 
                                                <div className="form-container">
                                                {
                                                    this.state.markers.map((el, index) => {
                                                        if(el.istable) return undefined;
                                                        return (
                                                        <div key={index + el.key} className="form-group has-field">
                                                        {!el.istable && 
                                                            <div className={`meta-container${
                                                                this.state.formObject[el.key].isTouched && (!this.state.formObject[el.key].helpText || this.state.formObject[el.key].isError) ? ' error' : ''
                                                            }`}>
                                                                <h4 className="meta-heading">{`Create metadata for: ${el.name}`}</h4>
                                                                <label htmlFor={`type-${el.key}`}>Select field type for: {el.name} <span>*</span></label>
                                                                <div className="flex-container radio-selection vertical-middle">
                                                                    <div className="flexbox half">
                                                                        <Select id={`type-${el.key}`} value={this.state.fieldTypes.filter(option => option.value === this.state.formObject[el.key].type)} className="input-select" name={`input-type-${el.key}`} options={this.state.fieldTypes} placeholder={`Select field type for ${el.name}`} onChange={(e) => this.setInput('type', el.key, e.value)}/>
                                                                    </div>
                                                                    <div className="flexbox half required">
                                                                        <label className="custom-input normal-text" data-type="checkbox">
                                                                            Is {el.name} a required field?
                                                                            <input type="checkbox" name={`required_${el.key}`} value={true} checked={this.state.formObject[el.key].required} onChange={(e) => this.setInput('required', el.key, e.target.checked)}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {this.state.formObject[el.key].hasOption && 
                                                                    <div className="options">
                                                                        {this.state.formObject[el.key].type === 'date' && 
                                                                            <div>
                                                                                <label htmlFor={`format-${el.key}`}>Please select the date format <span>*</span></label>
                                                                                <Select id={`format-${el.key}`} value={this.state.dateFormats.filter(option => option.value === this.state.formObject[el.key].format)} className="input-select" name={`input-type-${el.key}`} options={this.state.dateFormats} placeholder={`Select field type for ${el.name}`} onChange={(e) => this.setInput('format', el.key, e.value)}/>
                                                                            </div>
                                                                        }
                                                                        {this.state.formObject[el.key].type === 'file' && 
                                                                            <div>
                                                                                <label htmlFor={`format-${el.key}`}>Please select the selectable file format <span>*</span></label>
                                                                                <Select id={`format-${el.key}`} value={this.state.fileFormats.filter(option => option.value === this.state.formObject[el.key].format)} className="input-select" name={`input-type-${el.key}`} options={this.state.fileFormats} placeholder={`Select field type for ${el.name}`} onChange={(e) => this.setInput('format', el.key, e.value)}/>
                                                                            </div>
                                                                        }
                                                                        {this.state.formObject[el.key].type !== 'date' && this.state.formObject[el.key].type !== 'file' && <div>
                                                                            <label htmlFor="option">Create/Edit options for {this.state.formObject[el.key].type}</label>
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th><img src={dlt} className={`icon ${this.state.formObject[el.key].selectedIndex.length === 0 ? 'disabled' : ''}`} alt="icon" onClick={() => this.setInput('deleteOption', el.key)}/></th>
                                                                                        <th>Label</th>
                                                                                        <th>Value</th>
                                                                                        <th>Is default?</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.formObject[el.key].options.map((opt, opi) => (
                                                                                        <tr key={opi}>
                                                                                            <td>
                                                                                                <label className="custom-input" data-type="checkbox">
                                                                                                    <input type="checkbox" name="troe[]" checked={this.state.formObject[el.key].selectedIndex.indexOf(opi) > -1} onChange={() => this.setInput('selectedIndex', el.key, opi)}/>
                                                                                                    <span className="checkmark"></span>
                                                                                                </label>
                                                                                            </td>
                                                                                            <td>{opt.label}</td>
                                                                                            <td>{opt.value}</td>
                                                                                            <td>{opt.isDefault ? 'True' : 'False'}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                    }
                                                                                    <tr className="input">
                                                                                        <td>
                                                                                            <img src={plus} className={`plus icon${this.state.formObject[el.key].optionForm.isValid?'' : ' disabled'}`} alt="icon" onClick={() => this.setInput('option', el.key)}/>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type="text" className="input-field" placeholder="Option label" autoComplete="off" required="required" onChange={(e) => this.setInput('label', el.key, e.target.value)} value={this.state.formObject[el.key].optionForm.label} onKeyUp={(e) => this.bindEnter(e, el.key)}/>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type="text" className="input-field" placeholder="Option value"  autoComplete="off" onChange={(e) => this.setInput('value', el.key, e.target.value)} required="required" value={this.state.formObject[el.key].optionForm.value} onKeyUp={(e) => this.bindEnter(e, el.key)}/>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label className="custom-input normal-text" data-type="checkbox">
                                                                                                Default value?
                                                                                                <input type="checkbox" name={`is_default${el.key}`} value={true} checked={this.state.formObject[el.key].optionForm.isDefault} onChange={(e) => this.setInput('isDefault', el.key, e.target.checked)}/>
                                                                                                <span className="checkmark"></span>
                                                                                            </label>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                <div className="help-text">
                                                                    <label htmlFor={`help-${el.key}`}>Write down help text for: {el.name} <span>*</span></label>
                                                                    <input type="text" id={`help-${el.key}`} className="input-field" placeholder={`Write down help text for "${el.name}"`} autoComplete="off" tabIndex={index} onChange={(e) => this.setInput('helpText', el.key, e.target.value)} required="required" value={this.state.formObject[el.key].helpText}/>
                                                                </div>
                                                            </div>
                                                        }
                                                        </div>
                                                        )
                                                    })
                                                }
                                                </div>
                                                }
                                                {this.state.stepNumber === 1 && 
                                                    <div className="form-container group-creation">
                                                        <div className="groups">
                                                        {
                                                                Object.entries(this.state.groupObject).map((value, index) => (
                                                                    <div className="group form-group" key={index}>
                                                                        <div className="group-name">
                                                                            <div className="flex-container vertical-middle">
                                                                                <div className="flexbox name">{value[0]}</div>
                                                                                <div className="flexbox buttons">
                                                                                    <button className="btn small" onClick={() => {this.editGroupName(value)}}>Edit</button>
                                                                                    <button className="btn small" onClick={() => {this.deleteGroup(value)}}>Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="options">
                                                                            <div className="flex-container wrap">
                                                                            {
                                                                                this.state.markers.map((el, index) => (
                                                                                    <div className="flexbox input" key={index}>
                                                                                        <label className={`custom-input${this.state.groupUsedKeys.indexOf(el.key) > -1 && value[1].indexOf(el.key) === -1 ? ' used' : ''}`} data-type="checkbox">
                                                                                            {el.key.replace(/_/g, ' ')}
                                                                                            <input type="checkbox" name={value[0]} value={el.key} checked={value[1].indexOf(el.key) > -1} onChange={() => this.setGroup(value[0], el.key)}/>
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            <div className="form-group has-field">
                                                                <label htmlFor={`group-name`}>{this.state.groupNameIndex === -1 ? 'Create a new group' : 'Update group name'}</label>
                                                                <input type="text" id={`group-name`} className="input-field" placeholder={`Write down group name and press enter`} autoComplete="off" onChange={(e) => this.setState({groupName: e.target.value})} required="required" value={this.state.groupName}/>
                                                            </div>
                                                            <button className="btn moral" disabled={!this.state.groupName} onClick={() => this.createUpdateGroups()}>{this.state.groupNameIndex === -1 ? 'Create group' : 'Update group name'}</button>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.stepNumber === 2 && 
                                                    <div className="form-container">
                                                        <div className="form-group has-field">
                                                            <label htmlFor="outputformat">Select output file format <span>*</span></label>
                                                            <Select 
                                                                id="outputformat" 
                                                                value={[
                                                                    {value: 'docx', label: 'DOCX'},
                                                                    {value: 'pdf', label: 'PDF'}
                                                                ].filter(
                                                                    option => option.value === this.state.output.format
                                                                )}
                                                                className="input-select" 
                                                                name="output-file-format" 
                                                                options={[
                                                                    {value: 'docx', label: 'DOCX'},
                                                                    {value: 'pdf', label: 'PDF'}
                                                                ]}
                                                                placeholder="Select output file format"
                                                                onChange={(e) => this.outputForm('format', e)}
                                                            />
                                                        </div>
                                                        <div className="form-group has-field">
                                                            <label htmlFor="logo">Select logo for public link</label>
                                                            <input 
                                                                type="file"
                                                                id="logo" 
                                                                className="input-field" 
                                                                placeholder="Select logo for public form"
                                                                autoComplete="off"
                                                                accept={this.state.fileFormats[0].value}
                                                                onChange={(e) => {
                                                                    this.outputForm('logo', e)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                
                                                <div className="footer text-right">
                                                    {this.state.stepNumber > 0 && <button 
                                                        className="btn" 
                                                        onClick={() => this.setState({
                                                            stepNumber: this.state.stepNumber - 1 > 0 ? this.state.stepNumber - 1 : 0
                                                        })} 
                                                        disabled={this.state.submitting}
                                                        type="button"
                                                    >Prev step</button>}
                                                    <button 
                                                        type={this.state.stepNumber < 2 ? "button" : "submit"}
                                                        className="btn moral" 
                                                        onClick={() => {
                                                            if(this.state.stepNumber === 0){
                                                                const status = this.validateForm(0);
                                                                if(status){
                                                                    this.setState({
                                                                        stepNumber: 1
                                                                    }, () => { 
                                                                        this.validateForm(1); 
                                                                    })
                                                                }
                                                                else{
                                                                    this.nextButtonClicked();
                                                                }
                                                            }
                                                            else if(this.state.stepNumber === 1){
                                                                this.setState({
                                                                    stepNumber: 2
                                                                }, () => { 
                                                                    this.validateForm(2); 
                                                                })
                                                            }
                                                        }}
                                                        disabled={(this.state.stepNumber > 0 && !this.state.stepValidator[this.state.stepNumber]) || this.state.submitting}
                                                    >
                                                        {this.state.stepNumber < 2 ? 'Next step' : (this.state.create ? 'Create metadata' : 'Update metadata')}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default FieldMetaData;